<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <ArtistList @viewArtist="viewArtist" :artists="artists" />
  </div>
</template>

<script>
import ArtistList from "../components/ArtistList.vue";
// import { searchArtist } from "@/api/artists.api";
import useSpotifyApi from "@/common/composables/useSpotifyApi";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { saveMongoArtistDetail } from "@/api/user.api";
import {getArtistsBySpotifySearch, getArtistUUIDBySpotifyID} from "@/api/mongoDB";
export default {
  name: "Artists",
  components: {
    ArtistList,
  },
  data() {
    return {
      artists: [],
      term: "",
      loading: false,
      store: useStore(),
    };
  },
  async mounted() {
    if (this.$route.query.search) {
      // this.$store.dispatch(
      //   "EventsModule/searchArtist",
      //   this.$route.query.search
      // );
      await this.getArtists(this.$route.query.search);
    }
  },
  computed: {
    // searchTerm() {
    //   return this.$store.getters["EventsModule/getArtistSearchQuery"];
    // },
    searchQuery() {
      return this.$route.query.search;
    },
  },
  watch: {
    async searchQuery(newValue) {
      this.loading = true;
      await this.getArtists(newValue);
      this.loading = false;
    },
  },
  methods: {
    async getArtists(searchedArtist) {
      if (searchedArtist !== null && searchedArtist !== "") {
        try {
          this.loading = true;
          // const data = await searchArtist(searchedArtist);
          const { data } = await getArtistsBySpotifySearch(searchedArtist);
          this.artists = data ? data : [];
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      } else {
        this.artists = [];
      }
    },
    async viewArtist(spotifyId) {
      if (spotifyId) {
        try {
          this.loading = true;
          const { data } = await getArtistUUIDBySpotifyID(spotifyId);
          await this.store.dispatch("ArtistModule/setArtistUUID", data);
          if (data !== null || data !== "") {
            this.openArtistProfileDrawer(data);
          } else {
            Swal.fire({
              title:
                "<span style='color:#2F4C6E'>Artist info doesn't exist on SoundChart, if you want to see chartmetric info, please click on proceed</span>",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: "#0095E8",
              confirmButtonText: "Proceed",
            }).then(async (result) => {
              if (result.isConfirmed === true) {
                await saveMongoArtistDetail(spotifyId);
                window.open(`/ai-artist/${spotifyId}`, "_blank");
              } else {
                console.log("not proceed");
              }
            });
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
