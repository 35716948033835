<template>
  <el-row class="artist-list mb-10" :gutter="24">
    <el-col
      :xl="4"
      :lg="6"
      :sm="12"
      v-for="(artist, key) in artists"
      v-bind:key="key"
    >
      <el-card
        class="box-card aside-dark"
        @click="$emit('viewArtist', artist.id)"
      >
        <div class="text-center">
          <el-avatar :size="150" :src="artistImage(artist.images)"></el-avatar>
          <h3 class="artist-name truncate">
            {{ artist.name.toUpperCase() }}
          </h3>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "ArtistList",
  data() {
    return {
      loading: true,
    };
  },
  props: {
    artists: {
      type: Array,
      required: true,
    },
  },
  methods: {
    artistImage(images) {
      if (images.length > 0) {
        return images[0].url;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
